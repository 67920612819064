<template>
    <v-app>
        <v-card flat class="text-center">
            <v-card-title class="justify-center">
                Result
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-select
                                outlined
                                dense
                                label="Grade"
                                item-text="title"
                                item-value="id"
                                @change="getExamsByGrade"
                                v-model="gradeId"
                                :items="grades">

                        </v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                                outlined
                                dense
                                label="Exam"
                                item-text="title"
                                item-value="id"
                                @change="getResultByExams"
                                v-model="examId"
                                :items="examResults">

                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="results">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-center">
                                Subject Code
                            </th>
                            <th class="text-center">
                                Subject
                            </th>
                            <th class="text-center">
                                Credit Hr
                            </th>
                            <th class="text-center">
                                Obtain Marks
                            </th>
                            <th class="text-center">
                                Remarks
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in results.courses"
                            :key="index">
                            <td>IT302</td>
                            <td>{{item.exam_course_title}}</td>
                            <td>30</td>
                            <td><span v-if="item.theory_obtained_marks"> TH : {{item.theory_obtained_marks}}</span>
                                <br> <span
                                        v-if="item.practical_obtained_marks"> PR: {{item.practical_obtained_marks}}</span>
                            </td>
                            <td>{{item.remarks ? item.remarks : '-'}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">Result :
                                {{results.result}}
                            </td>
                            <td>Rank : {{results.rank}}</td>
                            <td>Total Mark :
                                {{results.total_obtained_mark}}
                            </td>
                            <td>Total GPA : {{results.total_gpa}}</td>

                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
    import StudentResultService from "@/core/services/user/student/result/StudentResultService";
    const studentResultService = new StudentResultService;
    import UserService from "@/core/services/user/UserService";
    const userService = new UserService();

    export default {
        name: "Exam",
        data() {
            return {
                examId: 0,
                gradeId: null,
                examResults: [],
                results: [],
                grades: [],
                user: null,
                studentSetting: null,

            }
        },
        watch: {
            examId(newValue, oldValue) {
                this.getResultByExams();
            },
            // gradeId(newValue, oldValue) {
            //     this.getExamsByGrade();
            // }
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        mounted() {
            this.getUser();
            this.getStudentClass();
        },
        methods: {
            getUser() {
                userService.show(this.currentUser.id).then(response => {
                    this.user = response.data.user;
                    this.user.is_active ? (this.user.is_active = true) : false;
                    this.user.is_email_verified
                        ? (this.user.is_email_verified = true)
                        : false;
                    this.studentSetting = this.user.setting ? this.user.setting : {}
                    this.gradeId = this.studentSetting.grade_id;
                    this.getExamsByGrade();
                });
            },
            getStudentClass() {
                studentResultService.getGrades(this.currentUser.id).then((res) => {
                    this.grades = res.data.classes;
                })
            },
            getExamsByGrade() {
                studentResultService.getExamsByGrades(this.currentUser.id, this.gradeId).then((res) => {
                    this.examResults = res.data.exams;
                    if (this.examResults.length){
                        this.examId = this.examResults[0].id;
                        this.getResultByExams();
                    }
                    // console.log(res)
                })
            },
            getResultByExams() {
                this.$bus.emit('toggleLoader',);
                studentResultService.getResultByExams(this.currentUser.id, this.examId).then((res) => {
                    this.results = res.data.result;
                    this.$bus.emit('toggleLoader',);
                    // console.log(res)
                })
            },

        },
    }
</script>

<style scoped>

</style>
